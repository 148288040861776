import React from 'react'
import styled from 'styled-components'
import Time from './Time'
import { colors } from '../tokens'

const Header = styled.header`
  margin-bottom: 2rem;
  color: ${colors.textLight};
  font-size: 0.9em;
`

class ContentIntro extends React.Component {
  render() {
    const { date } = this.props

    return (
      <Header>
        {date && <Time date={date} />}
      </Header>
    )
  }
}

export default ContentIntro
